<template>
    <div  v-if="canAccess('campus_read')"  class="p-grid">
        <div class="p-col-12">
            <div class="card card-w-title">
                <ProgressBar mode="indeterminate" v-if="processing"/>
                <DataTable :value="items" :filters="filters" class="p-datatable-responsive"
                           v-model:selection="currentCampusItem" selectionMode="single" dataKey="id" :paginator="true"
                           paginatorPosition="bottom" :rows="10">
                    <template #header>
                        <div class="table-header">
                            <h5 class="p-m-0">Gesti&oacute;n de Sedes </h5>
                            <div>
                                 <span class="p-input-icon-left p-ml-3">
                                    <i class="pi pi-search"/>
                                    <InputText v-model="filters['global']" placeholder="Search"/>
                                 </span>
                                <Button  v-if="canAccess('campus_create')" label="Agregar" icon="pi pi-plus" class="p-button-success p-ml-2" @click="openNew"/>
                            </div>
                        </div>
                    </template>

                    <Column field="id" header="Código" :sortable="true"></Column>
                    <Column field="name" header="Nombre" :sortable="true"></Column>
                    <Column field="code_nbs" header="Cod. NBS3" :sortable="true"></Column>
                    <Column field="created_at" header="Fecha de creación" :sortable="true"></Column>
                    <Column>
                        <template #body="slotProps">
                            <Button  v-if="canAccess('campus_update')" icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                                    @click="edit(slotProps.data)"/>
                            <Button  v-if="canAccess('campus_delete')" icon="pi pi-trash" class="p-button-rounded p-button-warning"
                                    @click="confirmDelete(slotProps.data)"/>
                        </template>
                    </Column>
                </DataTable>
                <Dialog  v-if="canAccess('campus_update') || canAccess('campus_create')"  v-model:visible="formDialog" :style="{width: '450px'}" header="Sede" :modal="true"
                        class="p-fluid">
                    <br>
                    <div class="p-field   p-fluid" v-if="currentCampusItem">
                          <span class="p-float-label">
                             <InputText id="name" v-model.trim="currentCampusItem.name" required="true" autofocus name="name"
                                        :class="{'p-invalid': submitted && (!currentCampusItem.name)}" type="text"/>
                              <label for="name">Nombre</label>
                          </span>
                          <small class="p-invalid" v-if="submitted && !currentCampusItem.name">El nombre es requerida</small>
                    </div>
                    <br>
                    <div class="p-field   p-fluid" v-if="currentCampusItem">
                            <span class="p-float-label">
                               <InputText id="code_nbs" v-model.trim="currentCampusItem.code_nbs" required="true" autofocus name="code_nbs"
                                          :class="{'p-invalid': submitted && (!currentCampusItem.code_nbs)}" type="number"/>
                                <label for="name">C&oacute;digo NBS</label>
                            </span>
                      <small class="p-invalid" v-if="submitted && !currentCampusItem.code_nbs">El c&oacute;digo NBS es requerida</small>
                    </div>
                    <br>
                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                        <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveItem"/>
                    </template>
                </Dialog>

                <Dialog  v-if="canAccess('campus_delete')" v-model:visible="confirmDeleteModal" :style="{width: '450px'}" header="Confirmaci&oacute;n de registros" :modal="true">
                    <div class="confirmation-content">
                        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
                        <span v-if="currentCampusItem">Seguro de eliminar registro?</span>
                    </div>
                    <template #footer>
                        <Button label="No" icon="pi pi-times" class="p-button-text"
                                @click="confirmDeleteModal = false"/>
                        <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteRow"/>
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>

  </div>

</template>

<script>
    import service from '../../service/catalog.service'
    import {mapState} from "vuex";
    import bouncer from "../../../../helpers/bouncer";
    export default {
      mixins:[bouncer],
        name: 'Campus',
        data() {
            return {
                path: 'campus',
                confirmDeleteModal: false,
                processing: false,
                submitted: false,
                formDialog: false,
                filters: {},
                items: null,
                currentCampusItem: null,
                layout: 'list',
                lastWeek: null,
                campus: []
            }
        },
        carService: null,
        eventService: null,
        nodeService: null,
        mounted() {
          if(this.canAccess('campus_read')){
            this.getData()
          }
        },
        methods: {
            saveItem() {
                this.submitted = true;
                if (!this.currentCampusItem.name || !this.currentCampusItem.code_nbs)  {
                    return;
                }
                this.processing = true;
                this.formDialog = false;
                service.save(this.path, this.currentCampusItem, this.currentCampusItem ? this.currentCampusItem.id : undefined).then(() => {
                    this.getData();
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Registro guardado',
                        detail: 'Se guardó correctamente',
                        life: this.$utils.toastLifeTime()
                    });

                }).catch((err) => {
                    const message = err.response.data;
                    let error = this.$utils.formatError(message);
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
                }).finally(() => {
                    this.submitted = false;
                    this.processing = false;
                });
            },
            hideDialog() {
                this.submitted = false;
                this.currentCampusItem = {};
                this.formDialog = false;
            },
            openNew() {
                this.submitted = false;
                this.currentCampusItem = {};
                this.formDialog = true;
            },
            getData() {
                this.processing = true;
                service.get(this.path).then(x => {
                    this.items = x.data;
                }).catch((error) => {
                  this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: this.$utils.formatError( error.response.data),
                    life: this.$utils.toastLifeTime()
                  });
                }).finally(() => {
                    this.processing = false;
                })
            },
            confirmDelete(data) {
                this.currentCampusItem = data;
                this.confirmDeleteModal = true;
            },
            edit(item) {
                this.currentCampusItem = item;
                this.submitted = false;
                this.formDialog = true;
            },
            deleteRow() {
                this.processing = true;
                this.confirmDeleteModal = false;
                service.delete(this.path, this.currentCampusItem.id).then(() => {
                    this.currentCampusItem = {};
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Registro eliminado',
                        detail: 'Se eliminó correctamente',
                        life: this.$utils.toastLifeTime()
                    });
                    this.getData();
                }).finally(() => {
                    this.processing = false;
                });
            },
        },
        computed: {
            ...mapState('auth', ['isAdmin']),
        }
    }
</script>

<style scoped>
    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .confirmation-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .p-progressbar {
        height: 1px;
    }
</style>
